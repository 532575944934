.loginBox {
  display: block;
  max-width: 600px;
  width: 100%;
  margin: 24px 0;
  background: #f6f7f9;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 14px;

  input {
    background-color: transparent;
    color: var(--primaryText);
    padding: 16px 24px;
    border-radius: 14px;
    width: 65%;
    font-size: 16px;
  }

  button {
    padding: 16px 24px !important;
    border-radius: 14px !important;
    width: 25%;
  }
}
