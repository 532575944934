.shop {
  display: flex;
  width: 100%;
  margin: 30px 0;
  flex-direction: row;
  flex-wrap: wrap;

  .product {
    width: 33%;
    padding: 12px;

    .inner {
      width: 100%;
      background: rgba(157, 157, 157, 0.11);
      border-radius: 24px;

      .thumbnail {
        height: 150px;
        width: 100%;
        border-radius: 24px 24px 0 0;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .details {
        padding: 16px 8px;

        .top {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;

          .title {
            width: 65%;
            color: var(--primaryText);
          }

          .price {
            width: 35%;
            color: var(--primary);
          }
        }

        .description {
          font-size: 14px;
          line-height: 20px;
          margin-top: 6px;
          color: var(--secondaryText);
        }

        .full_desc_btn {
          margin: 6px 0;
        }

        .counter {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin: 16px 0;

          .change {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: rgba(246, 91, 91, 0.14);
            color: var(--primary);
            font-size: 14px;
            padding: 0;
            margin: 0;
          }

          .counter_input {
            background: #e5e8ed;
            border-radius: 24px;
            padding: 2px 4px;
            margin: 0 8px;
            text-align: center;
            color: var(--primaryText);
            font-size: 16px;
            width: 80px;
          }
        }

        button {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 16px 0;

    .change {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: rgba(246, 91, 91, 0.14);
      color: var(--primary);
      font-size: 14px;
      padding: 0;
      margin: 0;
    }

    .counter_input {
      background: #e5e8ed;
      border-radius: 24px;
      padding: 2px 4px;
      margin: 0 8px;
      text-align: center;
      color: var(--primaryText);
      font-size: 16px;
      width: 80px;
    }
  }

  .full_description {
    display: block;
    position: relative;

    .header {
      display: flex;
      margin-bottom: 28px;

      h2 {
        width: 80%;

        &.cost {
          width: 20%;
          text-align: right;
        }
      }
    }

    .points {
      margin-top: 28px;
      margin-bottom: 28px;

      .point {
        margin-bottom: 20px;

        .point_desc {
          padding-left: 14px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .counter {
        margin-right: 24px;
      }
    }
  }

  .totalCost_footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 24px;

    .inner_space {
      width: 100%;
      max-width: 360px;
    }
  }
}

@media screen and (max-width: 600px) {
  .shop {
    .product {
      width: 100%;
    }
  }
}
