* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary: #f26522;
  --primaryText: #000000;
  --secondaryText: #9e9d9c;
  --greyBG: #ededed;
}

body {
  min-height: 100vh;
  font-family: "Manrope", sans-serif;
  color: #000;
  background-color: #fff;

  .min_container {
    min-height: 80vh;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    color: var(--primaryText);
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    color: var(--primaryText);
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
  }

  .red {
    color: var(--primary);
  }

  a {
    text-decoration: none;
  }

  .heading_sub_info {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--secondaryText);

    &.red,
    .red {
      color: var(--primary);
    }

    a.red {
      color: var(--primary);
      text-decoration: none;

      &:visited {
        color: var(--primary);
      }
    }

    &.black {
      color: var(--primaryText);
    }
  }

  .help_text {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--secondaryText);

    &.red {
      color: var(--primary);
    }

    .red {
      color: var(--primary);
    }

    &.black {
      color: var(--primaryText);
    }

    .light {
      color: var(--secondaryText);
    }
  }

  .name {
    text-transform: capitalize;
  }

  input {
    outline: none;
    border: none;
  }

  select {
    -webkit-appearance: none;
    outline: none;
    border: none;
  }

  button,
  .btn {
    cursor: pointer;
    user-select: none;
    outline: none;
    border: none;
    font-size: 12px;
    background-color: var(--greyBG);
    color: var(--primaryText);
    padding: 12px;
    border-radius: 8px;
    transition: all 300ms ease-in-out;

    &:hover {
      color: var(--primary);
    }

    &.primary {
      background-color: var(--primary);
      color: #ffffff;
      padding: 16px 24px;
      border-radius: 18px;
      font-size: 16px;
      height: fit-content;

      &:hover {
        color: #ffffff;
      }

      &.light {
        background-color: var(--greyBG);
        color: var(--primaryText);
      }

      &.small {
        padding: 12px;
        border-radius: 8px;
        font-size: 12px;

        svg {
          width: auto;
          height: 12px;
        }
      }
    }

    &.pill {
      border-radius: 18px;
      padding: 8px 12px;
    }

    &.red {
      background-color: rgba(226, 109, 56, 0.1);
      color: var(--primary);
    }

    &.svg_button {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  .fifty {
    width: 50%;
  }

  .divider {
    display: block;
    width: 100%;
    margin: 12px 0;
    border: 0.5px solid #e9e9e9;
  }

  .container {
    width: 1000px;
    max-width: 100%;
    padding: 0 12px;
    margin: 0 auto;
  }

  // Waiting Loader
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    z-index: 9999;
    transition: all 300ms ease-in-out;

    .loader_icon {
      display: block;
      width: 150px;
      height: 150px;
      background-image: url(./assets/loaders/loader.gif);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.destroy {
      opacity: 0;
    }
  }
}

// Tables
table {
  width: 100%;
  border-collapse: collapse;
  margin: 50px auto;
}

th {
  font-weight: bold;
  border-bottom: 1px solid var(--secondaryText);
}

td,
th {
  padding: 10px;
  text-align: left;
  font-size: 18px;
}

.center {
  text-align: center;
}

button:disabled,
button[disabled] {
  background-color: grey !important;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  }
}

@media screen and (max-width: 600px) {
  body {
    .fifty {
      width: 100%;
    }
  }
}
