.header {
  display: block;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .logo {
    width: 110px;

    img {
      display: block;
      width: 100%;
    }
  }

  .links {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--primaryText);
      margin-left: 28px;
      text-decoration: none;

      &.active {
        color: var(--primary);
      }
    }

    .accounts {
      position: relative;

      .selected {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 4px;
        }
      }

      .children {
        position: absolute;
        top: 115%;
        left: 6px;
        width: 100%;
        background: rgba(255, 255, 255, 0.79);
        box-shadow: 16px 22px 29px rgba(0, 0, 0, 0.07);
        backdrop-filter: blur(12px);
        padding: 5px 10px;
        border-radius: 8px;
        z-index: 2;

        .child {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: var(--primaryText);
          cursor: pointer;
          margin: 8px 0;
          text-align: center;

          &.selected_child {
            background-color: var(--primary);
            color: #ffffff;
            border-radius: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }

        .add_child {
          cursor: pointer;
          margin-top: 8px;
          margin-left: auto;
          margin-right: auto;
          background-color: transparent;
          width: 36px;
          height: 36px;
          padding: 0;
          display: block;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .login_btn {
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;

      span {
        margin-left: 8px;
      }

      svg path {
        transition: all 300ms ease-in-out;
      }

      &:hover {
        svg path {
          stroke: var(--primary);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    .links {
      width: 100%;
      margin-top: 20px;

      a {
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;
      }

      .login_btn {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
