.account {
  display: block;
  min-height: 80vh;

  .header {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 44px;
      color: var(--primaryText);
      margin-bottom: 0px;
    }

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 20px;

    .two_buttons {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: row;
      align-items: center;

      button {
        margin: 0 6px;
      }
    }
  }
}
