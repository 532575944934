.login {
  width: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.dynamic_height {
    min-height: auto;
  }

  .try_again {
    cursor: pointer;
  }
}
