.purchase_history {
  display: block;
  min-height: 80vh;

  .history_table {
    width: 100%;
    display: block;

    th,
    td {
      width: 18%;
    }
  }
}
