.popup_bg_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  .popup_container {
    width: 90%;
    max-width: 700px;
    max-height: 90%;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.07);
    border-radius: 36px;
    padding: 60px 30px;
    margin-bottom: 25px;
    width: 100%;
    position: relative;
  }
  .popup_cancel_button {
    display: flex;
    justify-content: end;
    cursor: pointer;

    svg circle {
      fill: var(--primary);
    }
  }
}

@media screen and (max-width: 600px) {
  .popup_bg_layer {
    .popup_container {
      transform: translateY(-2%);
    }
  }
}
