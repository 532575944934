.dashboard {
  display: block;
  min-height: 80vh;
  padding: 40px 0;

  .assesments {
    width: 100%;

    .assesment {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      filter: drop-shadow(0px 22px 29px rgba(0, 0, 0, 0.07));
      border-radius: 24px;
      padding: 12px;
      background: #ffffff;
      margin-bottom: 24px;
      position: relative;

      .assign_license_form {
        margin: 4vh 2vh 1vh 2vh;

        button {
          margin-top: 2vh;
          font-weight: bold;
        }
      }

      .error {
        color: var(--primary);
        margin: 10px 5px;
        font-size: 20px;
      }

      .thumbnail {
        width: 24%;
        border-radius: 24px;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .details {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.child {
          width: 75%;
        }

        .heading {
          font-size: 24px;
          line-height: 34px;
        }
        .completion_container {
          margin-top: 12px;

          .completion {
            background: #eef1f4;
            border-radius: 18px;
            padding: 4px 10px;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .linked {
          position: relative;
          padding: 8px 13px;
          background: #f6f7f9;
          border-radius: 12px;
          margin-top: 12px;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          &:hover {
            background-color: #e3e5ea;

            .heading.add_more {
              opacity: 1;
            }
          }

          .heading {
            font-size: 12px;
            line-height: 18px;
            color: var(--primaryText);
            margin-bottom: 4px;

            &.add_more {
              position: absolute;
              right: 8px;
              top: 8px;
              pointer-events: none;
              opacity: 0;
              transition: all 300ms ease-in-out;
            }
          }

          .children_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .child {
              background: rgba(216, 0, 39, 0.1);
              border-radius: 18px;
              padding: 4px 10px;
              color: var(--primary);
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 12px;
              margin-bottom: 8px;

              .remove {
                background-color: transparent;
                margin-left: 6px;
                height: 10px;
                width: 10px;
                padding: 0;

                svg path {
                  fill: var(--primary);
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .action {
        width: 24%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .available {
          display: block;

          .count {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: var(--primaryText);
            text-align: right;
          }

          .open_assesment {
            width: 100%;
          }
        }
      }

      .to_add_list {
        position: relative;
        width: 100%;
        padding: 8px 13px;
        background: #f6f7f9;
        border-radius: 12px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 8px;
        display: none;

        &.show {
          display: flex;
        }

        .item {
          background: rgba(216, 0, 39, 0.1);
          border-radius: 18px;
          padding: 4px 10px;
          color: var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          cursor: pointer;
          transition: all 300ms ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .assessments {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .assessment_link {
          width: 100%;
          margin-bottom: 8px;

          a {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            span {
              display: flex;

              svg {
                width: 24px;
                height: 24px;
                margin-left: 8px;

                path {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .license_expired_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;

    button {
      margin: 0 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    .assesments {
      .assesment {
        .thumbnail {
          width: 100%;
        }
        .details {
          width: 100%;
          margin-top: 8px;

          .children_list {
            .child {
              margin-top: 6px;
              margin-bottom: 6px;
            }
          }
        }
        .action {
          width: 100%;
          margin-top: 12px;
        }
      }
    }
  }
}
