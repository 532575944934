.order_summary_container {
  margin-top: 60px;
  margin-bottom: 60px;
}

.order_information {
  display: flex;
  align-items: center;
}

.order_information_item {
  flex-grow: 1;
}

.order_information_item:nth-child(3) {
  flex-grow: 2;
  text-align: center;
}

.order_information_item:nth-child(3) .qty_field_container {
  margin: 0 auto;
}

.order_information_item:last-child .order_information_item_value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total_price {
  display: inline-block;
  min-width: 65px;
  text-align: left;
}

.order_information_item_title {
  color: var(--secondaryText);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 31px;
}

.order_information_item_value {
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

/* PRICE BREAKDOWN */
.order_summary_price_breakdown {
  border-top: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.price_breakdown_item {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.price_breakdown_item:nth-child(2) {
  flex-direction: column;
}

.price_breakdown_item_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.price_breakdown_item_child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.helptext {
  margin-top: 23px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--secondaryText);
  text-align: right;
}

.price_breakdown_item:last-child {
  flex-direction: column;
  align-items: flex-end;
}

.price_breakdown_item_child .price_breakdown_item_title {
  width: 280px;
  max-width: 280px;
}

.price_breakdown_item:last-child .price_breakdown_item_value {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

.price_breakdown_item_child .price_breakdown_item_title {
  padding-right: 63px;
}

.price_breakdown_item_child .price_breakdown_item_title,
.price_breakdown_item_child .price_breakdown_item_value {
  text-align: right;
}

.price_breakdown_item_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--secondaryText);
}

.price_breakdown_item_value {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.price_breakdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-lable {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: right;
  color: #9e9d9c;
}

.price_breakdown_cta_container {
  display: flex;
  margin-bottom: 21px;
}

.price_breakdown_cta_container .price_breakdown_item_value {
  margin-right: 24px;
}

.order_summary_price_breakdown a {
  display: flex;
  text-align: center;
  width: 229px;
  height: 69px;
  padding: 0;
}

/* BLURS */
.order_summary_blur_left,
.order_summary_blur_right {
  position: absolute;
  width: 772px;
  height: 559.95px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  transform: rotate(-26.28deg);
}

.order_summary_blur_left {
  left: -266px;
  bottom: 0;
  opacity: 0.1;
}

.order_summary_blur_right {
  right: 0;
  top: 127.75px;
  opacity: 0.05;
}

/* responisve*/
@media only screen and (max-width: 767px) {
  .checkbox-row {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }

  .checkbox-row .checkbox-block {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1160px) {
  .order_summary_price_breakdown {
    flex-direction: column;
  }

  .price_breakdown_item {
    width: 100%;
  }

  .Order-confirmation-price-block {
    flex-direction: column;
  }

  .Order-confirmation-price-items:nth-child(1)
    .Order-confirmation-value-block:last-child {
    width: 100% !important;
  }

  .Order-confirmation-price-items:nth-child(1)
    .Order-confirmation-value-block:last-child
    .Ocon-value-title {
    text-align: right;
    width: 100%;
    max-width: 100%;
    justify-content: flex-end;
  }

  .Order-confirmation-price-items:nth-child(3) {
    margin-top: 20px;
    width: 100% !important;
  }

  .Order-confirmation-price-items:nth-child(4) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Order-confirmation-price-block {
    flex-direction: column;
  }

  .Order-confirmation-price-items:nth-child(1)
    .Order-confirmation-value-block:last-child {
    width: 100% !important;
  }

  .Order-confirmation-price-items:nth-child(1)
    .Order-confirmation-value-block:last-child
    .Ocon-value-title {
    text-align: right;
    width: 100%;
    max-width: 100%;
    justify-content: flex-end;
  }

  .Order-confirmation-price-items:nth-child(3) {
    margin-top: 20px;
    width: 100% !important;
  }

  .Order-confirmation-price-items:nth-child(4) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .order_information {
    flex-direction: column;
  }

  .order_information_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 0;
  }

  .order_information_item:last-child {
    border-bottom: none;
  }

  .order_information_item .order_information_item_title {
    padding-bottom: 0;
    border-bottom: none;
  }

  .order_information_item .order_information_item_value {
    margin-top: 0;
  }

  .order_information_item:nth-child(3) .qty_field_container {
    margin: 0;
  }
}

.Order-confirmation-price-block {
  border-top: solid 1px #e9e9e9;
  padding-top: 24px;
}

.Order-confirmation-price-block {
  display: flex;
  justify-content: space-between;
}

.Order-confirmation-price-items {
  min-width: 23%;
}

.Order-confirmation-value-block {
  display: flex;
  justify-content: space-between;
}

.Ocon-value-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #9e9d9c;
  max-width: 300px;
}

.Ocon-value {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.text-right {
  justify-content: flex-end;
}

.Order-confirmation-price-items:nth-child(3) {
  width: auto;
  min-width: 127px;
}

.Order-confirmation-price-items:nth-child(4) {
  width: 200px;
  min-width: 200px;
}

.Order-confirmation-price-items:nth-child(4) .btn {
  width: 200px;
  min-width: 200px;
  padding: 15px 17px;
  text-align: center;
}

.checkbox-row {
  display: flex;
  align-items: center;
}

.checkbox-row .bold {
  font-weight: bold;
}

.checkbox_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
