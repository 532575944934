.footer {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;

  .inner {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 14px 0px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    border-top: 1px solid #e1e1e0;

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: var(--secondaryText);
      text-decoration: none;

      &:visited {
        color: var(--secondaryText);
      }
    }

    .side {
      width: 50%;

      &.service_links {
        display: flex;
        justify-content: flex-end;

        .text {
          margin-left: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1338px) {
  .footer {
    .inner {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media screen and (max-width: 660px) {
  .footer {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 0;

    .inner {
      .side {
        .text {
          font-size: 12px;
        }

        &.service_links {
          display: block;

          .text {
            margin-left: 0;
            margin-bottom: 2px;
            text-align: end;
            display: block;
          }
        }
      }
    }
  }
}
