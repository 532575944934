.breadcrumbs {
  width: 100%;
  display: flex;
  margin: 12px 0;

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--secondaryText);
    margin-right: 18px;
    text-decoration: none;
    transition: all 300ms ease-in-out;

    svg path {
      transition: all 300ms ease-in-out;
    }

    &.active,
    &:hover {
      color: var(--primary);

      path {
        stroke: var(--primary);
      }
    }

    &.go_back {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        height: 12px;
        margin-right: 2px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .breadcrumbs {
    a {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
