.form_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;

  &.one-column {
    justify-content: flex-start !important;
  }

  &.full_input {
    .form_field {
      max-width: 100%;

      .input_field {
        width: 100%;
      }
    }
  }

  &.right {
    justify-content: flex-end;
  }
}

.form_heading_area {
  margin-bottom: 26px;
  text-align: center;
}

input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--secondary);

  a {
    text-decoration: underline;
    color: var(--primaryText);
  }
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #9d9d9d;
  border-radius: 0.2em;
  display: inline-block;
  width: 14px;
  height: 14px;
  padding-top: 0;
  padding-left: 0.3em;
  padding-right: 0.2em;
  padding-bottom: 0.6em;
  margin-right: 8px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 23px;
}

.order-confirm-not-checked input[type="checkbox"] + label:before {
  border: 0.1em solid red !important;
}

.checkbox-block {
  margin-right: 0px;
}

.not-checked {
  border: 1px solid red;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.error {
  display: inline-block;
  margin-top: 5px;
  color: #d10707;
}
.input_error {
  outline-color: #d10707 !important;
}

.form_field {
  width: 49%;

  &.full_input {
    width: 100%;
  }

  &.flex {
    display: flex;
    flex-wrap: wrap;

    &.right {
      justify-content: flex-end;
    }
  }
}
.input_field {
  width: 100%;
}

.phone_country_code {
  width: 120px;
  margin-right: 16px;
}
.phone_input {
  flex-grow: 2;
}
.input_field {
  background-color: #f6f7f9;
  border-radius: 16px;
  padding: 19px 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primaryText);
  outline: 2px solid transparent;
  width: 100%;
  margin-top: 5px;
}
.form_field label {
  padding: 10px;
}

.input_field:focus-visible {
  outline-color: rgb(216 0 39 / 50%);
}

.phone_field_container {
  display: flex;
  align-items: center;
}

select[name="countryCallingCode"] {
  padding-right: 2px;
}

button[type="submit"] {
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.full {
    width: 100%;
    padding: 19px 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .form_row {
    margin: 0;
    .form_field {
      width: 100%;
      margin: 8px 0;
    }
  }
}
