.successful_promoter_page_container {
	width: 100%;
	min-height: 90vh;
	overflow-y: hidden;
	margin: 0 auto;
	text-align: left;
	padding: 0 25px;
	position: relative;
	z-index: 11;
  }
  
  .celebrate_img {
	max-width: 106px;
	margin-bottom: 8px;
  }
  
  .successful_promoter_page_container .page_sub_heading {
	margin-bottom: 14px;
  }
  
  #successful_promoter {
	position: relative;
	min-height: 60vh;
	margin-top: 40px;
  }
  
  .bt-successful_promoter {
	min-height: 74vh !important;
	display: flex;
  }
  
  #successful_promoter .page_sub_heading {
	margin-top: 12px;
  }
  
  #successful_promoter .successful_promoter_blur_left,
  #successful_promoter .successful_promoter_blur_right {
	position: absolute;
	transform: rotate(-26.28deg);
	width: 772px;
	height: 559.95px;
	background: radial-gradient(
	  50% 50% at 50% 50%,
	  #f19202 0%,
	  rgba(241, 146, 2, 0) 100%
	);
	pointer-events: none !important;
  }
  
  #successful_promoter .successful_promoter_blur_left {
	left: -266px;
	bottom: 0;
	opacity: 0.1;
  }
  
  #successful_promoter .successful_promoter_blur_right {
	right: 0;
	top: 127.75px;
	opacity: 0.05;
  }
  
  #successful_promoter h3 {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 33px;
	color: #f19202;
	margin-top: 22px;
  }
  
  .list {
	margin-left: 20px;
  }
  
  .list li {
	list-style: decimal;
  }
  