.totalCost {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .cost {
    width: 59%;
    font-size: 20px;
    color: var(--primaryText);
    text-align: right;
    padding-right: 25px;

    span {
      color: var(--secondaryText);
      font-weight: normal;
    }

    p {
      font-size: 15px;
      color: var(--secondaryText);
    }
  }

  a,
  .submit_btn {
    width: 41%;

    .proceed {
      width: 100%;
      height: 100%;
    }
  }
}
